import * as React from "react";

type Props = {
  children: React.ReactNode;
  type?: "button" | "submit";
  className?: string;
  disabled?: boolean;
  dark?: boolean;
};

export default function Button({
  children,
  type,
  className,
  disabled,
  dark,
}: Props) {
  const buttonType = type ? type : "button";

  const colorScheme = dark
    ? "bg-gray-200 text-gray-800 hover:bg-gray-100"
    : "bg-gradient-to-br from-primary-600 to-primary-400 hover:from-primary-500 hover:to-primary-300 text-white";

  return (
    <button
      type={buttonType}
      className={`px-4 py-3 rounded font-bold shadow ${className} ${colorScheme}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
}