import * as React from "react";

export default function WordMark({ className, labelId }) {
  return (
    <svg
      version="1.1"
      viewBox="0 0 54.459 7.0083"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-labelledby={labelId}
      role="img"
    >
      <title id={labelId}>Dotte Web Design</title>
      <g transform="translate(291.06 109.43)">
        <g strokeWidth=".26458">
          <g fill="#fff">
            <path d="m-291.06-109.3h2.6776q0.90805 0 1.5988 0.33372 0.6985 0.32597 1.0788 0.9391 0.38806 0.61313 0.38806 1.4436 0 0.83044-0.38806 1.4436-0.38029 0.61313-1.0788 0.94686-0.69074 0.32596-1.5988 0.32596h-2.6776zm2.6 4.0047q0.58984 0 0.93909-0.33373 0.35701-0.34149 0.35701-0.95462 0-0.61312-0.35701-0.94685-0.34925-0.34149-0.93909-0.34149h-0.76835v2.5767z" />
            <path d="m-281.8-103.75q-0.877 0-1.5755-0.36477t-1.0943-1.009q-0.39582-0.65193-0.39582-1.4668 0-0.81491 0.39582-1.4591 0.39582-0.65194 1.0943-1.0167t1.5755-0.36477q0.87701 0 1.5755 0.36477t1.0943 1.0167q0.39581 0.64417 0.39581 1.4591 0 0.81492-0.39581 1.4668-0.39582 0.64418-1.0943 1.009t-1.5755 0.36477zm0-1.4824q0.34149 0 0.61313-0.16298 0.2794-0.17075 0.44238-0.47343 0.16299-0.31045 0.16299-0.72179 0-0.41133-0.16299-0.71402-0.16298-0.31044-0.44238-0.47343-0.27164-0.17074-0.61313-0.17074t-0.62089 0.17074q-0.27163 0.16299-0.43462 0.47343-0.16298 0.30269-0.16298 0.71402 0 0.41134 0.16298 0.72179 0.16299 0.30268 0.43462 0.47343 0.2794 0.16298 0.62089 0.16298z" />
            <path d="m-276.96-107.88h-1.591v-1.4203h5.0137v1.4203h-1.591v4.0125h-1.8316z" />
            <path d="m-271.81-107.88h-1.591v-1.4203h5.0137v1.4203h-1.591v4.0125h-1.8316z" />
            <path d="m-263.39-105.25v1.3815h-4.517v-5.4328h4.4161v1.3815h-2.6155v0.63641h2.2973v1.3194h-2.2973v0.71403z" />
          </g>
          <g fill="#fff">
            <path d="m-259.04-103.48 0.1397-8e-3c0.52775-0.0621 1.6764-2.0877 1.948-3.4459 0.0233-0.12417 0.0466-0.28716 0.0466-0.4579 0-0.42686-0.11641-0.89253-0.53551-0.89253h-0.0155c-0.0466 8e-3 -0.0699 0.0388-0.0699 0.0776 0 0.0233 8e-3 0.0466 0.031 0.0621 0.0698 0.0543 0.10089 0.17075 0.10089 0.34149 0 1.071-1.234 4.0901-1.6221 4.0901h-8e-3c-0.0931 0-0.14746-0.33373-0.14746-0.85373 0-0.86924 0.16298-2.2662 0.58984-3.4847v-0.0233c0-0.0776-0.16298-0.12418-0.34148-0.12418-0.19403 0-0.40358 0.0621-0.4191 0.18627-0.0698 0.21731-0.12418 0.42686-0.17851 0.63641-0.28716 0.7994-0.76835 2.2197-1.1719 2.988-0.0543 0.10865-0.38805 0.65193-0.56656 0.65193-0.0543 0-0.10089-0.0543-0.10865-0.20955v-0.0854c0-0.90029 0.7373-3.3761 0.7373-3.8961l-8e-3 -0.0543c-0.0388-0.21731-0.26388-0.36477-0.67522-0.36477-0.0621 0-0.13194 0-0.20179 8e-3 -0.65969 0.0543-1.428 1.2495-1.5833 1.8316-0.0155 0.0621-0.0233 0.12417-0.0233 0.18626 0 0.11642 0.0388 0.21731 0.1397 0.2794 0.0621 0.0311 0.13194 0.0466 0.20179 0.0466 0.12418 0 0.24836-0.0388 0.34925-0.0699 0.031-0.0155 0.0466-0.0466 0.0466-0.0698 0-0.0388-0.0233-0.0699-0.0621-0.0776-0.0155 0-0.0233-0.0233-0.0233-0.0543 0-0.27164 0.65969-1.5367 1.0167-1.7462 0.031-0.0155 0.0543-0.031 0.0854-0.031 0.0233 0 0.0466 0.0155 0.0466 0.0621v0.0233c-0.0931 0.58984-0.69074 2.5456-0.69074 3.6477 0 0.20955 0.0155 0.38806 0.0698 0.51999 0.0699 0.20179 0.35701 0.31045 0.63641 0.31045 0.0699 0 0.14747-8e-3 0.21732-0.0233 0.1785-0.0466 0.41133-0.35702 0.64417-0.76835 0.21731-0.3803 0.43462-0.86149 0.62865-1.3737-0.031 0.25612-0.0388 0.51999-0.0388 0.76059 0 0.31044 0.0233 0.5976 0.0543 0.83044 0.0466 0.3182 0.31821 0.57432 0.76059 0.57432z" />
            <path d="m-256.37-105.19c-0.0388 0-0.0776-8e-3 -0.10866-0.031 0.0621-0.18627 0.16298-0.38806 0.2794-0.56656 0.0776-0.10866 0.32597-0.43462 0.50447-0.43462 0.0233 0 0.0388 0 0.0621 0.0155s0.0388 0.0388 0.0388 0.0854c0 0.24836-0.44239 0.93133-0.77611 0.93133zm0.14746 1.3194c0.0776 0 0.17074-8e-3 0.27164-0.0388 0.31044-0.10866 0.7373-0.45791 1.2573-1.5445 8e-3 -0.0155 8e-3 -0.0311 8e-3 -0.0311 0-0.031-0.0155-0.0543-0.0388-0.0621-0.0155-8e-3 -0.031-0.0155-0.0466-0.0155-0.0233 0-0.0466 0.0155-0.0621 0.0466-0.48895 1.0167-0.87701 1.3504-1.1486 1.4513-0.0621 0.0155-0.12418 0.0233-0.17074 0.0233l-0.0854-8e-3c-0.25612-0.0543-0.34149-0.35701-0.34149-0.65194 0-0.10089 0.0155-0.20178 0.031-0.29492 0.0621 0.0543 0.1397 0.0776 0.21731 0.0776 0.4191 0 0.98566-0.67521 1.0012-1.2262v-0.0155c0-0.29493-0.2406-0.44239-0.53552-0.45015h-0.031c-0.56656 0-0.9779 0.59761-1.1642 1.0943-0.0931 0.24835-0.14746 0.50447-0.14746 0.74506 0 0.48895 0.24059 0.87701 0.90029 0.90029z" />
            <path d="m-254.64-103.86c0.0466 0 0.0776-0.0388 0.0776-0.0776 8e-3 -0.3182 0.19403-0.90805 0.30268-1.2107 0.0699-0.23283 0.45791-1.0012 0.69074-1.0167h0.0155c0.0543 0 0.0699 0.0466 0.0854 0.12418l0.0155 0.17851c0 0.26388-0.0776 0.60536-0.15522 0.82268-0.20179 0.0233-0.39582 0.0621-0.53552 0.19402-0.13194 0.1397-0.18627 0.29493-0.18627 0.43463 0 0.24059 0.16299 0.43462 0.41134 0.43462 0.0466 0 0.0931-8e-3 0.1397-0.0155 0.36477-0.1009 0.64417-0.49672 0.80716-0.9391 0.32596-0.0388 0.62865-0.14746 0.79939-0.51223 8e-3 -0.0155 8e-3 -0.031 8e-3 -0.0388 0-0.031-0.0155-0.0621-0.0388-0.0699-0.0155-8e-3 -0.031-8e-3 -0.0388-8e-3 -0.0233 0-0.0543 0.0155-0.0699 0.0543-0.11642 0.26388-0.35701 0.36477-0.60537 0.41134 0.0621-0.21731 0.1009-0.45791 0.1009-0.67522v-0.0699c-0.0233-0.36477-0.14746-0.76835-0.54328-0.76835h-0.0233c-0.19403 0-0.35701 0.12418-0.48895 0.28716 0.18626-0.52775 0.35701-0.97014 0.41134-1.1098l8e-3 -0.031c0-0.12418-0.26388-0.23283-0.45791-0.23283-0.0931 0-0.17074 0.0233-0.19403 0.0854-0.1397 0.29492-0.52775 1.3892-0.79163 2.2895-0.14746 0.49671-0.25612 0.93909-0.25612 1.1564l8e-3 0.0931c0.0388 0.17851 0.24059 0.20955 0.42686 0.20955zm0.55104-0.49671c-0.0466 0-0.0699-0.0466-0.0699-0.10089 0-0.0776 0.0388-0.17851 0.11641-0.25612 0.0776-0.0854 0.23284-0.13194 0.37254-0.15522-0.0776 0.17074-0.15523 0.28716-0.23284 0.37253-0.0776 0.1009-0.1397 0.1397-0.18626 0.1397z" />
            <path d="m-250.15-103.49c1.4901 0 2.5146-1.6376 2.5146-3.0501 0-0.11642-8e-3 -0.23283-0.0233-0.34925-0.13193-1.04-1.0012-1.4436-1.9713-1.4513h-0.0931c-0.90029 0-1.7773 0.21731-2.4913 0.83044-0.0699 0.0621-0.10089 0.17851-0.10089 0.30269 0 0.28716 0.16298 0.65969 0.34149 0.7373 0.0233 0.0155 0.0388 0.0155 0.0621 0.0155 0.0388 0 0.0699-0.0233 0.0776-0.0699 0.10089-0.47342 0.54327-0.877 1.1176-1.1331 0.4191-0.18627 0.90029-0.28716 1.3582-0.28716 0.17851 0 0.35701 0.0155 0.52 0.0466 0.38805 0.0776 0.5588 0.48895 0.5588 1.0167 0 1.1642-0.83044 2.8949-2.0412 2.9027v-0.0466c0-0.52775 0.93134-3.0501 1.0943-3.4692l8e-3 -0.031c0-0.12418-0.26388-0.24059-0.4579-0.24059-0.0931 0-0.17075 0.0233-0.19403 0.0854-0.23284 0.53551-1.009 2.9415-1.009 3.5701v0.0543c-0.17074-0.0388-0.39581-0.1397-0.58984-0.22508h-0.0155c-0.031 0-0.0621 0.0466-0.0621 0.10866 0 0.0233 8e-3 0.0543 0.031 0.0854 0.2794 0.35701 0.92357 0.59761 1.366 0.59761z" />
            <path d="m-246.74-105.19c-0.0388 0-0.0776-8e-3 -0.10865-0.031 0.0621-0.18627 0.16298-0.38806 0.2794-0.56656 0.0776-0.10866 0.32596-0.43462 0.50447-0.43462 0.0233 0 0.0388 0 0.0621 0.0155s0.0388 0.0388 0.0388 0.0854c0 0.24836-0.44238 0.93133-0.77611 0.93133zm0.14746 1.3194c0.0776 0 0.17075-8e-3 0.27164-0.0388 0.31045-0.10866 0.73731-0.45791 1.2573-1.5445 8e-3 -0.0155 8e-3 -0.0311 8e-3 -0.0311 0-0.031-0.0155-0.0543-0.0388-0.0621-0.0155-8e-3 -0.031-0.0155-0.0466-0.0155-0.0233 0-0.0466 0.0155-0.0621 0.0466-0.48895 1.0167-0.877 1.3504-1.1486 1.4513-0.0621 0.0155-0.12418 0.0233-0.17075 0.0233l-0.0854-8e-3c-0.25612-0.0543-0.34149-0.35701-0.34149-0.65194 0-0.10089 0.0155-0.20178 0.031-0.29492 0.0621 0.0543 0.1397 0.0776 0.21731 0.0776 0.4191 0 0.98566-0.67521 1.0012-1.2262v-0.0155c0-0.29493-0.24059-0.44239-0.53552-0.45015h-0.031c-0.56656 0-0.9779 0.59761-1.1642 1.0943-0.0931 0.24835-0.14746 0.50447-0.14746 0.74506 0 0.48895 0.2406 0.87701 0.90029 0.90029z" />
            <path d="m-244.8-103.87c0.23284 0 0.50448-0.0155 0.73731-0.27164 0.13194-0.14746 0.24835-0.30268 0.36477-0.48118 0.16298-0.24836 0.31821-0.53552 0.45791-0.83044l8e-3 -0.0311c0-0.031-0.0155-0.0543-0.0388-0.0699-0.0155-8e-3 -0.031-8e-3 -0.0388-8e-3 -0.031 0-0.0543 8e-3 -0.0699 0.0388-0.15523 0.33372-0.30269 0.58984-0.43463 0.79939-0.12417 0.18627-0.28716 0.39582-0.38805 0.50447 0.0699-0.10865 0.11642-0.24835 0.15522-0.42686 0.0155-0.0776 0.0233-0.15522 0.0233-0.23283 0-0.33373-0.10865-0.6597-0.10865-0.98566 0-0.20179 0.0466-0.41134 0.18627-0.62089 0.0155-0.0155 0.0155-0.0388 0.0155-0.0543 0-0.0388-0.031-0.0699-0.0776-0.0699h-0.57433c-0.14746 0-0.55103 0.94686-0.61312 1.0866-8e-3 0.0155-8e-3 0.0388-8e-3 0.0543 0 0.0388 0.0233 0.0466 0.0388 0.0543 0.0155 8e-3 0.0233 8e-3 0.0388 8e-3 0.0233 0 0.0543-0.0155 0.0699-0.0388l0.2794-0.60537c-8e-3 0.0543-8e-3 0.10866-8e-3 0.16298 0 0.34149 0.0931 0.77612 0.0931 1.1486 0 0.21731-0.031 0.41134-0.1397 0.55104-0.0388 0.0466-0.0931 0.0854-0.1397 0.0854-0.0155 0-0.0311 0-0.0466-0.0155-0.031-0.0233-0.0466-0.0621-0.0466-0.12418 0-0.16298 0.10865-0.43462 0.23283-0.53552 0.0233-0.0155 0.031-0.0388 0.031-0.0543 0-0.10866-0.22507-0.27164-0.40357-0.27164-0.0621 0-0.12418 0.0233-0.16299 0.0776-0.11641 0.16298-0.1785 0.34925-0.1785 0.52775 0 0.33373 0.21731 0.62865 0.67521 0.62865z" />
            <path d="m-242.97-103.87c0.35701-0.0155 0.83044-1.0322 1.0788-1.5833 8e-3 -0.0155 8e-3 -0.0233 8e-3 -0.0388 0-0.031-0.0155-0.0543-0.0388-0.0621l-0.0388-8e-3c-0.031 0-0.0543 8e-3 -0.0699 0.0466-0.21731 0.48895-0.6985 1.4824-0.95461 1.4901-0.0466 0-0.0621-0.0466-0.0621-0.13194 0-0.47343 0.54327-2.0179 0.60536-2.1886l8e-3 -0.031c0-0.11641-0.24059-0.23283-0.42686-0.23283-0.0854 0-0.16298 0.0311-0.19402 0.0931-0.11642 0.28717-0.37254 0.9779-0.49672 1.56-0.0388 0.20179-0.0699 0.39581-0.0699 0.5588 0 0.1397 0.0233 0.26388 0.0699 0.34149 0.0854 0.16298 0.28717 0.18626 0.48119 0.18626zm0.56656-3.0268c0.0621 0 0.11642-0.0155 0.13194-0.0543 0.0543-0.11641 0.16298-0.39581 0.20179-0.46566 0.0155-0.0155 0.0155-0.0388 0.0155-0.0543 0-0.11642-0.23283-0.2406-0.41134-0.2406-0.0854 0-0.15522 0.0233-0.17851 0.0931-0.0466 0.10089-0.1397 0.40357-0.1397 0.52775 0 0.1009 0.22508 0.19403 0.3803 0.19403z" />
            <path d="m-241.46-104.46h-8e-3c-0.0776 0-0.10865-0.0776-0.10865-0.20179 0-0.45791 0.44238-1.5057 0.80715-1.5134 0.11642 0 0.15523 0.1009 0.15523 0.21731 0 0.17851-0.0776 0.39582-0.1009 0.47343-0.0854 0.21731-0.51223 1.0245-0.74506 1.0245zm-0.38806 2.0412c0.65193 0 0.9779-0.65193 1.1874-1.1874 0.31821-0.12418 0.54328-0.33373 0.75283-0.63641s0.39581-0.70626 0.62865-1.2107c8e-3 -0.0155 8e-3 -0.0233 8e-3 -0.0311 0-0.0388-0.0233-0.0621-0.0466-0.0699-0.0155-8e-3 -0.0233-8e-3 -0.031-8e-3 -0.031 0-0.0621 0.0233-0.0698 0.0466-0.23284 0.49671-0.41134 0.89253-0.61313 1.1874-0.16298 0.23283-0.33373 0.40358-0.55104 0.51999 0.17851-0.48895 0.35701-1.071 0.52-1.591 0.10865-0.37253 0.21731-0.70626 0.30268-0.95461l8e-3 -0.0233c0-0.10865-0.21731-0.23283-0.40358-0.23283-0.0776 0-0.15522 0.0155-0.20955 0.0699-0.031 0.0233-0.0466 0.0776-0.0543 0.12418-0.10089-0.12418-0.25611-0.18627-0.43462-0.18627h-8e-3c-0.76835 0-1.3272 1.0943-1.3272 1.8627 0 0.40358 0.15522 0.71402 0.52776 0.72178h0.0155c0.26388 0 0.48119-0.24059 0.65969-0.49671-0.0931 0.33373-0.13194 0.48895-0.22507 0.74507-0.70626 0.0388-1.0943 0.20955-1.1874 0.67521-8e-3 0.0543-0.0155 0.1009-0.0155 0.14747 0 0.28716 0.17074 0.50447 0.51223 0.52775zm-0.17074-0.34149c-0.15523 0-0.22508-0.10089-0.22508-0.23283 0-0.0311 0-0.0621 8e-3 -0.0931 0.0854-0.37254 0.42687-0.49672 0.96238-0.52-0.1785 0.43462-0.45014 0.84596-0.74506 0.84596z" />
            <path d="m-237.83-103.86 0.21731-8e-3c0.29492-0.0155 0.46567-0.34925 1.0089-1.5833 8e-3 -0.0155 8e-3 -0.0233 8e-3 -0.0311 0-0.0466-0.0388-0.0776-0.0854-0.0776-0.0233 0-0.0466 0.0155-0.0621 0.0466-0.54327 1.2185-0.68297 1.4746-0.877 1.4901-0.0388 0-0.0466-0.0466-0.0466-0.1397 0-0.45014 0.37254-1.4901 0.48119-2.0256l8e-3 -0.0931c0-0.20955-0.16298-0.32597-0.35701-0.32597-0.32597 0-0.65193 0.39582-0.88477 0.6985-0.1397 0.17851-0.27163 0.37253-0.38805 0.54328 0.0233-0.0621 0.2794-0.83044 0.34149-0.9779l8e-3 -0.031c0-0.12417-0.25612-0.23283-0.44238-0.23283-0.0931 0-0.17075 0.0311-0.19403 0.0931-0.19403 0.50448-0.65194 2.0334-0.65194 2.4292 8e-3 0.17851 0.25612 0.21731 0.44239 0.21731h0.0388c0.0621 0 0.1009-8e-3 0.1009-0.0776 8e-3 -0.1785 0.0854-0.48895 0.1785-0.83044 0.1397-0.24059 0.66746-1.1098 1.04-1.4436 0.0233-0.0233 0.0543-0.0466 0.0776-0.0466 0.0155 0 0.0233 0.0155 0.0233 0.0388-8e-3 0.0931-0.44239 1.3504-0.45791 1.917 0 0.38806 0.19403 0.45014 0.47343 0.45014z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
