import * as React from "react";
import { Link } from "gatsby";

import WordMark from "./SVG/WordMark";

const navData = [];

function Logo() {
  return <WordMark labelId="wordmark1" className="w-56 sm:w-80" />;
}

function NavItem({ name, to }) {
  return (
    <li>
      <Link to={to}>{name}</Link>
    </li>
  );
}

export default function Nav() {
  return (
    <nav className="relative flex p-4 lg:pt-10 lg:px-28 place-items-center">
      <Link to="/">
        <Logo />
      </Link>
      <ul className="flex ml-auto sm:text-lg space-x-10 font-bold">
        {navData.map((navObj) => (
          <NavItem key={navObj.name} to={navObj.to} name={navObj.name} />
        ))}
      </ul>
    </nav>
  );
}
