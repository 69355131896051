import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

type SEOType = {
  title?: string;
  description?: string;
  image?: string;
  article?: string;
  landingPage?: boolean;
};

export default function SEO({
  title,
  description,
  image,
  article,
  landingPage,
}: SEOType) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    slogan,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata;
  const seo = {
    title: title || `${defaultTitle} | ${slogan}`,
    titleTemplate: title ? titleTemplate : `%s`,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };
  return (
    <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
      <html lang="en" />
      <script type="application/ld+json">
        {landingPage &&
          `
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "${defaultTitle}",
            "url": "${siteUrl}",
            "logo": "${siteUrl}/images/logo.png"
          }`}
      </script>
      <script type="application/ld+json"></script>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {article ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta property="og:site_name" content={defaultTitle} />
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        slogan
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
      }
    }
  }
`;
